import { HomeOutlined } from "@ant-design/icons";
import { FloatButton, Image } from "antd";
import { useNavigate } from "react-router-dom";

export const Config = () => {
    const nav = useNavigate();
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Image
        src={`https://firebasestorage.googleapis.com/v0/b/scryptworxstudios.appspot.com/o/services%2FSound%20Desk.jpg?alt=media&token=d03dad9d-f56a-4f3e-bbdc-054431cf822f`}
        width="100%"
        height="100%"
      />
         <FloatButton
        icon={<HomeOutlined />}
        onClick={() => {
          nav("/");
        }}
      />
    </div>
  );
};
